<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Хэрэглэгчийн төлбөр</h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="addVisible = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <div class="panel">
      <el-table
        :data="deliveryChargeList"
        style="width: 100%; text-align: center"
      >
        <el-table-column label="№" prop="id" width="40px"> </el-table-column>
        <el-table-column label="Ангилал" prop="service">
          <template slot-scope="prop">
            {{ prop.row.service === "all" ? "Бүгд" : prop.row.service }}
          </template>
        </el-table-column>
        <el-table-column label="Суурь">
          <el-table-column prop="km_min" label="Доод км"> </el-table-column>
          <el-table-column prop="km_max" label="Дээд км"> </el-table-column>
          <el-table-column prop="base_price" label="Үнэ">
            <template slot-scope="scope">
              {{ mformatPrice(scope.row.base_price) }}₮
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="km_price" label="КМ-н үнэ">
          <template slot-scope="scope">
            {{ mformatPrice(scope.row.km_price) }}₮
          </template>
        </el-table-column>
        <el-table-column prop="is_default" label="Үндсэн">
          <template slot-scope="prop">
            <el-tooltip
              effect="dark"
              content="Хэрэв дээрх нөхцөлийг хангахгүй байгаа тохиолдолд энийг ашиглана"
              placement="top"
            >
              <el-switch
                v-model="prop.row.is_default"
                active-color="#13ce66"
                disabled
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Үйлдэл">
          <template slot-scope="prop">
            <el-button @click="editClick(prop.row)" type="text" size="small"
              >Засах</el-button
            >
            <el-button @click="deleteDialog(prop.row)" type="text" size="small"
              >Устгах</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="Хэрэглэгчийн төлбөр засах" :visible.sync="editVisible">
      <div v-if="editDialogData">
        <el-form
          :rules="rulesEdit"
          ref="editdeliveryChargeRef"
          :model="editDialogData"
          :data="editDialogData"
          label-position="left"
          label-width="130px"
          style="font-size: 12px; width: 400px; margin-left: 50px"
        >
          <el-form-item label="Ангилал" prop="services">
            <el-select
              :disabled="editDialogData.is_default === true"
              placeholder="Үйлчилгээ"
              clearable
              v-model="editDialogData.service"
            >
              <el-option value="food13" key="food13" label="тест"></el-option>
              <el-option
                v-for="(service, index) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Доод км" prop="km_min">
            <el-input v-model="editDialogData.km_min"></el-input>
          </el-form-item>
          <el-form-item label="Дээд км" prop="km_max">
            <el-input v-model="editDialogData.km_max"></el-input>
          </el-form-item>
          <el-form-item label="Суурь үнэ" prop="base_price">
            <el-currency-input
              v-model="editDialogData.base_price"
            ></el-currency-input>
          </el-form-item>
          <el-form-item label="Км тутам" prop="km_price">
            <el-currency-input
              v-model="editDialogData.km_price"
            ></el-currency-input>
          </el-form-item>
          <el-form-item label="Үндсэн эсэх">
            <el-tooltip
              effect="dark"
              content="Хэрэв дээрх нөхцөлийг хангахгүй байгаа тохиолдолд энийг ашиглана"
              placement="top"
            >
              <el-switch
                v-model="editDialogData.is_default"
                active-color="#13ce66"
              ></el-switch>
            </el-tooltip>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancelModal('edit')">Болих</el-button>
            <el-button type="primary" @click="save(editDialogData)">
              Хадгалах
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="Хэрэглэгчийн төлбөр нэмэх" :visible.sync="addVisible">
      <div v-if="addDeliveryCharge">
        <el-form
          :rules="rules"
          ref="deliveryChargeRef"
          :model="addDeliveryCharge"
          label-position="left"
          label-width="130px"
          style="font-size: 12px; width: 400px; margin-left: 50px"
        >
          <el-form-item label="Ангилал">
            <el-select
              placeholder="Үйлчилгээ"
              clearable
              :disabled="addDeliveryCharge.is_default"
              v-model="addDeliveryCharge.service"
            >
              <el-option value="food13" key="food13" label="тест"></el-option>
              <el-option
                v-for="(service, index) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Доод км" prop="km_min">
            <el-input v-model="addDeliveryCharge.km_min"></el-input>
          </el-form-item>
          <el-form-item label="Дээд км" prop="km_max">
            <el-input v-model="addDeliveryCharge.km_max"></el-input>
          </el-form-item>
          <el-form-item label="Суурь үнэ" prop="base_price">
            <el-currency-input
              v-model="addDeliveryCharge.base_price"
            ></el-currency-input>
          </el-form-item>
          <el-form-item label="КМ-н үнэ" prop="km_price">
            <el-currency-input
              v-model="addDeliveryCharge.km_price"
            ></el-currency-input>
          </el-form-item>
          <el-form-item label="Үндсэн эсэх">
            <el-tooltip
              effect="dark"
              content="Хэрэв дээрх нөхцөлийг хангахгүй байгаа тохиолдолд энийг ашиглана"
              placement="top"
            >
              <el-switch
                v-model="addDeliveryCharge.is_default"
                active-color="#13ce66"
              ></el-switch>
            </el-tooltip>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancelModal('add')"> Болих </el-button>
            <el-button
              type="primary"
              @click="addDeliveryChargeConfigs(addDeliveryCharge)"
            >
              Хадгалах
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="Хэрэглэгчийн хүргэлтийн төлбөр"
      :visible.sync="deleteVisible"
    >
      <span>Та устгахдаа итгэлтэй байна уу</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">Үгүй</el-button>
        <el-button type="primary" @click="deleteClick(deleteData)"
          >Тийм</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import ElCurrencyInput from "./ElCurrencyInput.vue";
export default {
  name: "UserPayment",
  components: {
    ElCurrencyInput
  },
  created() {
    this.getServices();
    this.deliveryChargeConfigs();
  },
  computed: {
    rulesEdit() {
      return {
        km_max: [
          {
            required: true,
            message: "Дээд км бичээгүй байна.",
            trigger: "change"
          }
        ],
        km_min: [
          {
            required: true,
            message: "Доод км бичээгүй байна.",
            trigger: "change"
          }
        ],
        base_price: [
          {
            required: true,
            message: "Суурь үнэ бичээгүй байна.",
            trigger: "change"
          }
        ],
        km_price: [
          {
            required: true,
            message: "Нэмэлт үнэ бичээгүй байна.",
            trigger: "change"
          }
        ]
      };
    },
    rules() {
      return {
        km_max: [
          {
            required: true,
            message: "Дээд км бичээгүй байна.",
            trigger: "change"
          }
        ],
        km_min: [
          {
            required: true,
            message: "Доод км бичээгүй байна.",
            trigger: "change"
          }
        ],
        base_price: [
          {
            required: true,
            message: "Суурь үнэ бичээгүй байна.",
            trigger: "change"
          }
        ],
        km_price: [
          {
            required: true,
            message: "Нэмэлт үнэ бичээгүй байна.",
            trigger: "change"
          }
        ]
      };
    }
  },
  methods: {
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    deleteDialog(value) {
      this.deleteData = Object.assign({}, value);
      this.deleteVisible = true;
    },
    deleteClick(data) {
      service.deleteDeliveryChargeConfigs(data.id).then(res => {
        if (res.data.message === "Амжилттай устгалаа") {
          this.$notify({
            title: "Амжилттай",
            message: res.data.message,
            type: "success"
          });
          this.deliveryChargeConfigs();
          this.deleteVisible = false;
        } else {
          this.$notify({
            title: "Алдаа гарлаа",
            message: res.data.message,
            type: "error"
          });
        }
      });
    },
    cancelModal(data) {
      if (data === "edit") {
        this.editDialogData = null;
        this.editVisible = false;
      } else if (data === "add") {
        (this.addDeliveryCharge.service = ""),
          (this.addDeliveryCharge.km_max = null),
          (this.addDeliveryCharge.km_min = null),
          (this.addDeliveryCharge.base_price = null),
          (this.addDeliveryCharge.km_price = null),
          (this.addDeliveryCharge.is_default = false),
          (this.addVisible = false);
      }
    },
    editClick(value) {
      value.base_price = parseInt(value.base_price);
      value.km_price = parseInt(value.km_price);
      this.editDialogData = Object.assign({}, value);
      this.editVisible = true;
    },
    async save(data) {
      this.$refs.editdeliveryChargeRef.validate(valid => {
        if (valid) {
          if (data.service === "" || data.service === null) {
            data.service = "all";
          }
          service.updateDeliveryChargeConfigs(data.id, data).then(res => {
            if (res.data.message === "Амжилттай засагдлаа") {
              this.$notify({
                title: "Амжилттай",
                message: res.data.message,
                type: "success"
              });
              this.deliveryChargeConfigs();
              this.editVisible = false;
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                message: res.data.message,
                type: "error"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Шаардлагатай талбаруудыг бөглөнө үү",
            type: "warning"
          });
        }
      });
    },
    async deliveryChargeConfigs() {
      await service.getDeliveryChargeConfigs("user").then(res => {
        if (res.data.status === "success") {
          this.deliveryChargeList = res.data.data;
        } else {
          this.$notify({
            title: "Алдаа",
            message: res.data.message,
            type: "error"
          });
        }
      });
    },
    async addDeliveryChargeConfigs(data) {
      this.$refs.deliveryChargeRef.validate(valid => {
        if (valid) {
          data.type = "user";
          if (data.service === "" || data.service === null) {
            data.service = "all";
          }
          service.createDeliveryChargeConfigs(data).then(res => {
            if (res.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Амжилттай хадгаллаа",
                type: "success"
              });
              this.deliveryChargeConfigs();
              this.addVisible = false;
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Шаардлагатай талбаруудыг бөглөнө үү",
            type: "warning"
          });
        }
      });
    },
    mformatPrice(value) {
      return parseInt(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  data() {
    return {
      deleteVisible: false,
      editVisible: false,
      servicesList: [],
      deliveryChargeList: [],
      editDialogData: null,
      addVisible: false,
      addDeliveryCharge: {
        service: "",
        km_max: null,
        km_min: null,
        base_price: null,
        km_price: null,
        is_default: false
      },
      is_default: false,
      deleteData: null
    };
  }
};
</script>
